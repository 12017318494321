<template>
  <main class="px-2 md:px-4 py-6 flex flex-col gap-10">
    <h4
      class="capitalize md:uppercase md:text-sm md:text-left text-xs font-medium text-headerText"
    >
      Book Keeping / Client / View
    </h4>
    <div class="flex justify-between items-center gap-6">
      <div class="flex gap-6 items-center">
        <easiBackButton />
        <p class="text-grey font-bold text-2xl">Customer Information</p>
      </div>

      <easiButton @click="editModal = true" class="gap-2">
        <img class="" src="@/assets/img/Edit.svg" alt="" />

        Edit Client</easiButton
      >
    </div>
    <section v-if="details.client" class="mt-12 grid md:grid-cols-3 gap-6">
      <!-- IMAGE /// -->

      <div
        class="border border-outlineGray rounded-2xl bg-white flex flex-col gap-6 p-6"
      >
        <div class="flex justify-center">
          <div
            class="rounded-full w-40 h-40 border-4 border-secondary overflow-hidden"
          >
            <div
              class="p-2 w-full h-full font-medium rounded-full flex items-center justify-center text-3xl md:text-5xl"
            >
              {{
                details.client.firstName.charAt(0) +
                "" +
                details.client.lastName.charAt(0)
              }}
            </div>
          </div>
        </div>
        <div>
          <p class="font-semibold text-2xl">
            {{ details.client.firstName + " " + details.client.lastName }}
          </p>
          <p class="font-medium text-xl">({{ details.client.companyName }})</p>
        </div>
        <!-- <p>
          Lorem ipsum dolor sit amet consectetur. Urna venenatis phasellus purus
          at nibh dignissim suspendisse venenatis vitae.
        </p> -->
        <div class="pb-14 flex flex-col gap-y-3">
          <div class="flex items-center gap-x-2">
            <img src="@/assets/img/newEmail.svg" class="w-5 h-5" alt="" />
            <p class="text-grey text-base font-medium break-all">
              {{ details.client.email }}
            </p>
          </div>
          <div class="flex items-center gap-x-2">
            <img src="@/assets/icons/newPhone.svg" class="w-5 h-5" alt="" />
            <p class="text-grey text-base font-normal">
              {{ details.client.phone }}
            </p>
          </div>
        </div>
      </div>

      <div
        class="border border-outlineGray rounded-2xl bg-white flex flex-col gap-6 p-6"
      >
        <p class="text-grey text-2xl font-medium">Company Details</p>
        <div>
          <p class="text-dark-200 font-normal text-base">Company Name:</p>

          <p class="text-lg font-medium">{{ details.client.companyName }}</p>
        </div>
        <div>
          <p class="text-dark-200 font-normal text-base">Company Address:</p>

          <p class="text-lg font-medium">
            {{ details.client.address }}
          </p>
        </div>
        <div>
          <p class="text-dark-200 font-normal text-base">Client Note:</p>
          <div class="mt-2 p-4 pb-12 text-grey bg-newAsh">
            {{ details.client.clientNote }}
          </div>
        </div>
      </div>
      <div
        class="border border-outlineGray rounded-2xl bg-white flex flex-col gap-6 p-6"
      >
        <p class="text-grey text-2xl font-medium">Customer Value</p>

        <div class="bg-newAsh py-4 px-6 rounded-lg">
          <p class="text-dark-800 text-sm">Total Spent</p>

          <p class="text-primary-deep font-bold">
            {{ formatAmount(details.totalPaidAmount) }}
          </p>
        </div>
        <hr />
        <div class="bg-newAsh py-4 px-6 rounded-lg">
          <p class="text-dark-800 text-sm">Number of Item bought</p>

          <p class="text-primary-deep font-bold">{{
            details.itemBoughtCount
          
          }}</p>
        </div>
        <hr />
        <div class="bg-newAsh py-4 px-6 rounded-lg flex gap-3">
          <img src="@/assets/img/fan.svg" class="w-20 h-14" alt="" />

          <div class="flex flex-col justify-between">
            <p class="text-dark-800 text-sm">Most bought item?</p>

            <p class="text-primary-deep font-bold">Ceiling Fan</p>
          </div>
        </div>
      </div>
    </section>

    <ClientTransactions v-if="details.receipt && details.receipt.length" />

    <easiModal
      v-if="editModal"
      @close="editModal = false"
      :isHeader="false"
      :persistence="true"
    >
      <div class="py-12">
        <p class="text-center text-dark-800 font-medium text-2xl">
          Edit Client
        </p>
        <AddEditClient :editMode="true" :data="details.client" @cancel="editModal=false" @done="handleEditdone"/>
      </div>
    </easiModal>
    <easiLoading v-if="isLoading" />
  </main>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useRoute } from "vue-router";

import ClientTransactions from "@/components/BookKeeping/Client/ClientTransactions";
import AddEditClient from "@/components/BookKeeping/Client/AddEditClient.vue";
import { GetAClient } from "@/services/book-keeping/client";

import { helperFunctions } from "@/composable/helperFunctions";

const { formatAmount } = helperFunctions;
const route = useRoute();

const clientId = computed(() => route.params.id || null);

console.log("id", clientId);
const isLoading = ref(false);
const details = ref({});
const handleEditdone = async () => {
  details.value = await GetAClient(clientId.value)
  editModal.value = false;
};
onMounted(async () => {
  try {
    isLoading.value = true;
    details.value = await GetAClient(clientId.value);

    console.log("details", details.value);
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
});
const editModal = ref(false);
</script>

<style lang="scss" scoped></style>
