<template>
  <div class="flex flex-col gap-2 sm:gap-4">
    <div class="flex items-center justify-between gap-4">
      <h3 class="font-semibold text-lg md:text-2xl">Transaction history</h3>
    </div>

    <easiPlainTable
      :data="visibleData"
      :headers="tableHeaders"
      v-model:selected="selectedData"
    >
    </easiPlainTable>
  </div>

  <!-- //////  Delete Modal ////// -->
  <easiAlert
    :icon="opsIcon"
    v-if="deleteModal"
    :title="`<span class='text-xl'>Are you sure you want to delete <br/> this item?</span>`"
  >
    <div class="pb-4 px-6 grid grid-cols-2 gap-4">
      <easiButton
        variant="outlined"
        size="medium"
        @click="deleteModal = false"
        block
        >Cancel</easiButton
      >
      <easiButton
        size="medium"
        :loading="deleteLoading"
        @click="handleDelete"
        block
        >Yes</easiButton
      >
    </div>
  </easiAlert>
  <!-- //////  Alert Modal ////// -->
  <easiAlert v-if="isAlert" :caption="alertMessage">
    <div class="pb-4 px-6">
      <easiButton
        size="medium"
        :loading="loading"
        @click="isAlert = false"
        block
        >Okay!</easiButton
      >
    </div>
  </easiAlert>
</template>

<script setup>
import opsIcon from "@/assets/icons/empty-state.svg";

import { ref } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { useRouter } from "vue-router";

const { formatAmount, formatDate } = helperFunctions;
const store = useDataStore();
const router = useRouter();

const alertMessage = ref("");
const isAlert = ref(false);

const deleteLoading = ref(false);
const deleteModal = ref(false);
const selectedItem = ref(null);

const selectedData = ref([]);
const visibleData = ref([
  {
    item: "table",
    receiptNo: "#21345600",
    quantity: 5,
    amount: 45000,
    createdAt: new Date(),
    status: "Paid",
    _id: 1,
  },
]);

const tableHeaders = [
  {
    style: ``,
    title: "ITEM",
    field: "item",
  },
  {
    style: ``,
    title: "RECEIPT NO",
    field: "receiptNo",
  },
  {
    style: ``,
    title: "QUANTITY",
    field: "quantity",
  },
  {
    style: ``,
    title: "AMOUNT",
    field: "amount",
    format: (val) => {
      return val ? formatAmount(val) : "--";
    },
  },

  {
    style: ``,
    title: "DATE",
    field: "createdAt",
    type: "date",
    format: (val) => {
      return val ? formatDate(val, "DD/MM/YYYY") : "--";
    },
  },

  {
    title: "Payment",
    field: "status",
    type: "action",
  },
];

const dropdownOptions = [
  {
    title: "View",
    action: (val) => {
      router.push({ name: "ViewClient", params: { id: val._id } });
    },
  },
  {
    title: "Edit",
    action: (val) => {
      alert("edit");
    },
  },
  {
    title: "Delete",
    action: (val) => {
      alert("delete");
    },
  },
];

const handleDelete = () => {
  deleteLoading.value = true;

  setTimeout(() => {
    deleteLoading.value = false;
    deleteModal.value = false;

    alertMessage.value = "Item successfully deleted";
    isAlert.value = true;
  }, 3000);
};
</script>

<style></style>
